<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar :color="colores.primario" dense dark><span :style="[{'font-size': '20px' }]">ESCALAMIENTOS</span></v-toolbar>
      <v-card-text class="pt-4">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="area_id" v-on:input="input_area(area_id)" item-text="descripcion" item-value="id" :items="items.areas" :color="colores.primario" label="ÁREA *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="1" v-if="area_id != 13 && session.area !== 17 || area_id == null ">
            <v-btn @click="area_id = 13" block large :color="colores.black" dark>CONTINUAR CON LA QR</v-btn>
          </v-col>
        </v-row>
        <div v-if="area_id !== 13 && area_id !==12 && area_id !== 0">
            <v-row no-gutters>
              <v-col cols="12">
                <escalamientosAgregar :colores="colores" :items_escalamiento="form_objs.items_escalamiento" :items_correos="items_correos"></escalamientosAgregar>
              </v-col>
            </v-row>
            <br><br>
            <v-row no-gutters>
              <v-col cols="12">
                <escalamientos :session="session" :gestion_id="gestion_id" :form_objs="form_objs" :colores="colores"></escalamientos>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="session.area !== 17">
              <v-col cols="12" class="text-center">
                <v-btn @click="validar" block large :color="colores.black" dark>ENVIAR ESCALAMIENTO</v-btn>
              </v-col>
            </v-row>
        </div>
        <qrConfirmarOtro v-if="area_id === 12" :session="session" :rules="rules" :codigo="registro.codigo" :gestion_id="gestion_id" :form_otro="form_otro" :colores="colores" @update_registro="refrescar_registro"></qrConfirmarOtro>
        <qrConfirmarCierre v-if="area_id === 13 && (registro.gesodt === null && registro.gesproducto.producto_id ===1 || registro.gesproducto.producto_id === 4)" :registro="registro" :form_odt="form_odt" :gestion_id="gestion_id" :session="session" :rules="rules" :items="items" :colores="colores"></qrConfirmarCierre>
        <qrCerrar v-if="area_id === 13 && ( (registro.gesodt === null && registro.gesproducto.producto_id !==1 && registro.gesproducto.producto_id !== 4) || ((registro.gesodt !== null && (registro.gesproducto.producto_id === 1 || registro.gesproducto.producto_id === 4))) )"  :token_area="token_area" :registro="registro" :items="items" :session="session" :rules="rules" :gestion_id="gestion_id" :colores="colores" @refresh_registro="refrescar_registro"></qrCerrar>
      </v-card-text>
    </v-card>
  </v-sheet>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
// import escalamientos from '@/components/crm/escalamientosComponent.vue'
// import escalamientosAgregar from '@/components/crm/escalamientos_agregarComponent.vue'
import recArea from '@/js/rec_areas.js'
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
// import qrConfirmarCierre from '@/components/crm/form_qr_confirmar_cierreComponent.vue'
// import qrConfirmarOtro from '@/components/crm/form_qr_confirmar_otroComponent.vue'
// import qrCerrar from '@/components/crm/form_qr_cerrarComponent.vue'

export default {
  name: 'escalamientosComponent',
  components: {
    escalamientosAgregar: () => import('@/components/crm/escalamientos_agregarComponent.vue'),
    escalamientos: () => import('@/components/crm/escalamientosComponent.vue'),
    qrConfirmarCierre: () => import('@/components/crm/form_qr_confirmar_cierreComponent.vue'),
    qrConfirmarOtro: () => import('@/components/crm/form_qr_confirmar_otroComponent.vue'),
    qrCerrar: () => import('@/components/crm/form_qr_cerrarComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recArea, recGestiones, recFunciones],
  props: ['registro', 'colores', 'items', 'session', 'form_objs', 'gestion_id', 'form_odt', 'rules', 'form_otro'],
  data: () => ({
    token_area: 0,
    loadingArea: false,
    area_id: 0,
    items_correos: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  methods: {
    input_area (pAreaId) {
      if (pAreaId === 13) {
        this.token_area = this.token_area + 1
      }
      this.rec_area_asesor(pAreaId)
    },
    validar () {
      this.dialogo.pregunta = true
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_escalamientos_datos()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    refrescar_registro (pdata) {
      this.$emit('update_registro', pdata)
    }

  }
}
</script>
